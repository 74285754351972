<template>
     <div class="tab-pane fade show active" id="orders" role="tabpanel">
        <h3>Заказы</h3>
        <div class="table-responsive">
            <table class="table" v-if="orderListTotal>0">
                <thead>
                    <tr>
                        <th>Номер</th>
                        <th>Дата</th>
                        <th>Статус</th>
                        <th>Сумма</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="ordersListLoading">
                    <tr v-for="item in previewOrderListQty" :key="item.id">
                        <td><n-skeleton size="small" round /></td>
                        <td><n-skeleton size="small" round /></td>
                        <td><n-skeleton size="small" round /></td>
                        <td><n-skeleton size="small" round /></td>
                        <td><n-skeleton size="small" round /></td>
                    </tr>
                </tbody>
                <tbody v-else-if="!ordersListLoading && orderListTotal>0">
                    <tr v-for="item in ordersList" :key="item.id">
                        <td>{{ item.number }}</td>
                        <td>{{ formatDate(item.date) }}</td>
                        <td><span class="success">-</span></td>
                        <td>{{ getOrderAmountById(item.id) }} &#8381;</td>
                        <td><a href="#" @click.prevent="" class="view"><i class="fa fa-search" aria-hidden="true"></i></a></td>
                    </tr>
                </tbody>
            </table>
            <div v-if="!ordersListLoading && orderListTotal==0" class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="about_content">
                            <h1>Заказов еще не создано</h1>
                            <div class="checkout_btn">
                                <router-link class="as-button" :to="{ name: 'catalog', query: { page: 1}}" href="#">В каталог</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OrderListPagination />
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import OrderListPagination from "@/components/User/Account/OrderListPagination.vue"
export default {
    name: "OrderListComponent",
    components: {
        OrderListPagination
    },
    data() {
        return {
            previewOrderListQty: 5
        }
    },
    setup() {
		const { cookies } = useCookies()
		return { cookies }
	},
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        ordersList () {
            return this.$store.getters.ordersList
        },
        ordersListLoading () {
            return this.$store.getters.ordersListLoading
        },
        orderListTotal () {
            return this.$store.getters.orderListTotal
        }
    },
    mounted () {
        this.loadOrdersList()
    },
    methods: {
        formatDate: d => new Date(d).toLocaleDateString('ru-RU'),
        loadOrdersList () {
            let authToken = this.cookies.get(`${cookiesToken}`) 
            if (authToken) {
                this.$store.dispatch('loadOrdersList', { authToken: authToken})
            } else if (this.userToken) {
                this.$store.dispatch('loadOrdersList', { authToken: this.userToken})
            }
        },
        getOrderAmountById (orderId) {
            return this.$store.getters.orderAmountByID(orderId)
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.loadOrdersList()
            }
        }
    }
}
</script>

<style>
.as-button {
    color: #FFF !important;
}
.checkout_btn {
    display: flex;
    justify-content: center;
}
</style>
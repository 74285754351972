import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        orders_list: [],
        pageQty: 0,
        ordersOnPageQty: 0,
        orders_list_loading: false,
        currentPage: 1,
        orderAvailPagesBeforeCurrent: [],
        orderAvailPagesAfterCurrent: [],
        orderListTotal: 0,
        orderCreationLoading: false,
        orderCreationStatus: {}
    },
    getters: {
        ordersList: state => {
            return state.orders_list
        },
        orderListQty: state => {
            return state.orders_list.length
        },
        ordersListLoading: state => {
            return state.orders_list_loading
        },
        pageQty: state => {
            return state.pageQty
        },
        ordersOnPageQty: state => {
            return state.ordersOnPageQty
        },
        currentPage: state => {
            return state.currentPage
        },
        orderAvailPagesBeforeCurrent: state => {
            return state.orderAvailPagesBeforeCurrent
        },
        orderAvailPagesAfterCurrent: state => {
            return state.orderAvailPagesAfterCurrent
        },
        orderListTotal: state => {
            return state.orderListTotal
        },
        orderCreationLoading: state => {
            return state.orderCreationLoading
        },
        orderCreationStatus: state => {
            return state.orderCreationStatus
        },
        orderAmountByID: state => id => {
            let order = state.orders_list.find(orderItem => orderItem.id === id)
            if (order) {
                return order.items.reduce((acc,item) => acc += (Number(item.summ)), 0).toFixed(2)
            }
            return 0
        }
    },
    mutations: {
        loadOrdersList (state, payload) {
            state.orders_list = payload
        },
        orderListLoadingSwitch (state, payload) {
            state.orders_list_loading = payload
        },
        setPageQty (state, payload) {
            state.pageQty = payload
        },
        setOrdersOnPageQty (state, payload) {
            state.ordersOnPageQty = payload
        },
        setCurrentPage (state, payload) {
            state.currentPage = payload
        },
        setOrderAvailPagesBeforeCurrent (state, payload) {
            state.orderAvailPagesBeforeCurrent = payload
        },
        setOrderAvailPagesAfterCurrent (state, payload) {
            state.orderAvailPagesAfterCurrent = payload
        },
        setOrderListTotal (state, payload) {
            state.orderListTotal = payload
        },
        orderCreationLoadingSwitch (state, payload) {
            state.orderCreationLoading = payload
        },
        setOrderCreationStatus (state, payload) {
            state.orderCreationStatus = payload
        }
    },
    actions: {
        setCurrentPage ({commit}, pageNumber) {
            commit('setCurrentPage', pageNumber)
        },
        clearOrderCreationStatus ({commit}) {
            commit('setOrderCreationStatus', {})
        },
        async loadOrdersList({commit}, params) {
            let page = 1
            let ordersOnPageQty = 10
            if (params.page) {
                page = params.page
            }
            if (params.ordersOnPageQty) {
                ordersOnPageQty = params.ordersOnPageQty
            }
            let authToken = params.authToken
            let url = `${backendPath}/api/v1/order/?page=${page}&count=${ordersOnPageQty}`
            commit('setOrdersOnPageQty', ordersOnPageQty)
            commit('orderListLoadingSwitch', true)
            await Axios({
                method: 'get',
                headers: {
                    "Authorization": `Token ${authToken}`
                },
                url: url
            }).then(response => {
                let pageQty = 1
                if (response.data.total % ordersOnPageQty > 0) {
                    pageQty = Math.floor(response.data.total/ordersOnPageQty) + 1
                } else {
                    pageQty = Math.floor(response.data.total/ordersOnPageQty)
                }
                commit('setPageQty', pageQty)
                commit('setCurrentPage', page)
                commit('loadOrdersList', response.data.data)
                let orderAvailPagesBeforeCurrent = []
                if (page <= 2) {
                    for (let i = 1; i < page; i++) {
                        orderAvailPagesBeforeCurrent.push(i)
                    }
                } else {
                    for (let i = (page-2); i < page; i++) {
                        orderAvailPagesBeforeCurrent.push(i)
                    }
                }
                orderAvailPagesBeforeCurrent = orderAvailPagesBeforeCurrent.sort((a, b) => {
                    return a - b
                })
                commit('setOrderAvailPagesBeforeCurrent', orderAvailPagesBeforeCurrent)
                let orderAvailPagesAfterCurrent = []
                if ((pageQty - page) <= 2) {
                    for (let i = (page+1); i <= pageQty; i++) {
                        orderAvailPagesAfterCurrent.push(i)
                    }
                } else {
                    for (let i = (page+1); i < (page+3); i++) {
                        orderAvailPagesAfterCurrent.push(i)
                    }
                }
                orderAvailPagesAfterCurrent = orderAvailPagesAfterCurrent.sort((a, b) => {
                    return a - b
                })
                commit('setOrderAvailPagesAfterCurrent', orderAvailPagesAfterCurrent)
                commit('setOrderListTotal', response.data.total)
                commit('setPageQty', pageQty)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('orderListLoadingSwitch', false)
            })
        },
        async placeAnOrder({commit}, params) {
            commit('orderCreationLoadingSwitch', true)
            commit('setOrderCreationStatus', {})
            let authToken = params.authToken
            let checkoutItems = params.checkoutItems
            let orderData = {
                "items": checkoutItems,
            }
            let data = {
                "data": [
                    orderData
                ]
            }
            let url = `${backendPath}/api/v1/order/`
            await Axios({
                method: 'post',
                headers: {
                    "Authorization": `Token ${authToken}`
                },
                url: url,
                data: data,
            }).then(() => {
                commit('setOrderCreationStatus', { status: 'success', message: 'Заказ успешно создан'})
                let clearCartUrl = `${backendPath}/api/v1/cart/clear/`
                Axios({
                    method: 'get',
                    headers: {
                        "Authorization": `Token ${authToken}`,
                    },
                    url: clearCartUrl,
                }).then(() => {
                    commit('loadCartData', {})
                }).catch(error => {
                    console.log(error)
                })
            }).catch(error => {
                console.log(error)
                commit('setOrderCreationStatus', { status: 'error', message: 'Не удалось создать заказ'})
            }).finally(() => {
                commit('orderCreationLoadingSwitch', false)
            })
        },
        clearOrdersList({commit}, payload) {
            commit('loadOrdersList', payload)
        },
        deleteAllCartItems({commit}, authToken) {
            let url = `${backendPath}/api/v1/cart/clear/`
            Axios({
                method: 'get',
                headers: {
                    "Authorization": `Token ${authToken}`,
                },
                url: url,
            }).then(() => {
                commit('loadCartData', {})
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
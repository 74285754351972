<template>
    <td>{{ checkoutItem.good.name }}</td>
    <td>{{ checkoutItem.good.price1 }} &#8381;</td>
    <td>{{ checkoutItem.good.price2 }} &#8381;</td>
    <td> 
        <n-space vertical>
            <n-input-number
                :disabled="orderCreationLoading"
                v-model:value="clientPrice"
                :default-value="clientPrice"
                :parse="parseCurrency"
                :precision="2"
                @update:value="setCheckoutListItemPrice"
                :update-value-on-input="false"
            >
                <template #suffix>
                    &#8381;
                </template>
            </n-input-number>
        </n-space>
    </td>
    <td>{{ quantity }}</td>
    <td>{{ amount }} &#8381;</td>
</template>

<script>
export default {
    name: "CheckoutItemComponent",
    props: ['checkoutItem'],
    setup() {
        return {
            parseCurrency: (input) => {
                const nums = input.replace(/(,|\$|\s)/g, "").trim()
                if (/^\d+(\.(\d+)?)?$/.test(nums)) {
                    return Number(nums)
                }
                return nums === "" ? null : Number.NaN
            }
        }
    },
    data () {
        return {
            id: this.checkoutItem.good.id,
            clientPrice: Number(this.checkoutItem.good.clientPrice),
            quantity: Number(this.checkoutItem.quantity)
        }
    },
    computed: {
        amount () {
            return (this.quantity * this.clientPrice).toFixed(2)
        },
        orderCreationLoading () {
            return this.$store.getters.orderCreationLoading
        }
    },
    methods: {
        setCheckoutListItemPrice () {
            this.$store.dispatch('setCheckoutListItemPrice', {
                id: this.id,
                clientPrice: this.clientPrice
            })
        }
    }
}
</script>
<template>
    <BreadcrumbComponent name="Регистрация" />
    <SignupComponent />
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import SignupComponent from "@/components/User/SignupComponent.vue"
export default {
    name: "SignupView",
    setup() {
		const { cookies } = useCookies()
		return { cookies }
	},
    mounted () {
        let authToken = this.cookies.get(`${cookiesToken}`)
        if (authToken) {
            this.$router.push({ name: 'home'})
        }
    },
    components: {
        BreadcrumbComponent,
        SignupComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Регистрация'
            }
        }
    }
    
}
</script>
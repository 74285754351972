<template>
    <footer class="footer_widgets">
        <div class="footer_top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-5">
                        <div class="widgets_container contact_us">
                            <div class="footer_logo">
                                <a href="#"><img :src="logo" alt=""></a>
                            </div>
                            <div class="footer_contact">
                                <p>We are a team of designers and developers that
                                    create high quality Magento, Prestashop, Opencart...</p>
                                <div class="customer_support">
                                    <div class="support_img_icon">
                                        <img :src="iconPhone" alt="">
                                    </div>
                                    <div class="customer_support_text">
                                        <p>
                                            <span>Customer Support</span>
                                            <a href="tel:(08)123456789">(08) 123 456 789</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-7">
                        <div class="widgets_container widgets_subscribe">
                            <h3>Subscribe newsletter to get updated</h3>
                            <div class="subscribe_form">
                                <form id="mc-form" class="mc-form footer-newsletter">
                                    <input id="mc-email" type="email" autocomplete="off" placeholder="Enter you email address here...">
                                    <button id="mc-submit">Subscribe</button>
                                </form>
                                <!-- mailchimp-alerts Start -->
                                <div class="mailchimp-alerts text-centre">
                                    <div class="mailchimp-submitting"></div><!-- mailchimp-submitting end -->
                                    <div class="mailchimp-success"></div><!-- mailchimp-success end -->
                                    <div class="mailchimp-error"></div><!-- mailchimp-error end -->
                                </div><!-- mailchimp-alerts end -->
                            </div>
                            <p>We’ll never share your email address with a third-party.</p>
                            <div class="footer_social">
                                <ul>
                                    <li><a class="facebook" href="#"><i class="ion-social-facebook"></i></a></li>
                                    <li><a class="twitter" href="#"><i class="ion-social-twitter"></i></a></li>
                                    <li><a class="youtube" href="#"><i class="ion-social-youtube"></i></a></li>
                                    <li><a class="google" href="#"><i class="ion-social-google"></i></a></li>
                                    <li><a class="instagram" href="#"><i class="ion-social-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="widgets_container widget_menu">
                            <h3>Information</h3>
                            <div class="footer_menu">
                                <ul>
                                    <li><a href="#">Delivery</a></li>
                                    <li><a href="about.html.htm">About Us</a></li>
                                    <li><a href="contact.html.htm"> Contact us</a></li>
                                    <li><a href="#">Stores</a></li>
                                </ul>
                                <ul>
                                    <li><a href="#">Legal Notice</a></li>
                                    <li><a href="#">Secure payment</a></li>
                                    <li><a href="#">Sitemap</a></li>
                                    <li><a href="my-account.html.htm"> My account</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="copyright_area">
                            <p class="copyright-text">&copy; 2022 <a href="index.html.htm">Esther</a>. Made with <i class="fa fa-heart text-danger"></i> by <a href="https://hasthemes.com/" target="_blank">HasThemes</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import iconPhone from "@/assets/images/icon/hotline.png"
    import logo from "@/assets/images/logo/logo.png"
    export default {
        name: "FooterComponent",
        data () {
            return {
                iconPhone,
                logo
            }
        }
    }
</script>
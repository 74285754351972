<template>
    <section class="mt-5 mb-5"></section>
</template>

<script>
export default {
    name: "IndexPage",
    components: {
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Главная'
            }
        }
    }
}
</script>
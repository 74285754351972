<template>
    <tbody>
        <tr v-for="item in previewCheckoutItemsCount" :key="item.id">
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
        </tr>
    </tbody>
    <tfoot>
        <tr class="order_total">
            <td colspan="4"></td>
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
        </tr>
    </tfoot>
</template>

<script>
export default {
    name: "CheckoutPreviewComponent",
    props: ['previewCheckoutItemsCount']
}
</script>
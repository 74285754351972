<template>
    <tbody>
        <tr v-for="item in demoWishlistItemsCount" :key="item.id">
            <td class="product_thumb"><n-skeleton height="40px" round /></td>
            <td class="product_name"><n-skeleton height="40px" round /></td>
            <td class="product-price"><n-skeleton height="40px" round /></td>
            <td class="product_quantity"><n-skeleton height="40px" round /></td>
            <td class="product_total"><n-skeleton height="40px" round /></td>
            <td class="product_remove"><n-skeleton height="40px" round /></td>
        </tr>
    </tbody>
</template>

<script>
export default {
    name: "WishlistPreviewComponent",
    props: ["demoWishlistItemsCount"]
}
</script>
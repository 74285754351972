<template>
    <CatalogContentComponent />
</template>

<script>
import CatalogContentComponent from '@/components/Catalog/CatalogContentComponent.vue'

export default {
    name: "CatalogView",
    components: {
        CatalogContentComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Каталог'
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
            }
        }
    }
}
</script>
import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        user: {},
        user_token: "",
		user_info_loading: false,
        sendPinCodeResultStatus: {},
        loginResultMessage: "",
        signupResultStatus: {},
        confirmSignupStatus: {}
    },
    getters: {
        user: state => {
            if (Object.keys(state.user).length === 0) {
                return ''
            } else {
                return state.user
            }
        },
        client: state => {
            if (Object.keys(state.user).length != 0) {
                if (state.user.client != null) {
                    return state.user.client
                }
            } 
            return ''
        },
        user_token: state => {
            return state.user_token
        },
		user_info_loading: state => {
            return state.user_info_loading
        },
        sendPinCodeResultStatus: state => {
            return state.sendPinCodeResultStatus
        },
        loginResultMessage: state => {
            return state.loginResultMessage
        },
        signupResultStatus: state => {
            return state.signupResultStatus
        },
        confirmSignupStatus: state => {
            return state.confirmSignupStatus
        }
    },
    mutations: {
        userInfoLoadingSwitch (state, payload) {
            state.user_info_loading = payload
        },
        setUserToken (state, payload) {
            state.user_token = payload
        },
        loadUserInfo (state, payload) {
            state.user = payload
        },
        loadSendPinCodeResultStatus (state, payload) {
            state.sendPinCodeResultStatus = payload
        },
        loadLoginResultMessage (state, payload) {
            state.loginResultMessage = payload
        },
        loadSignupResultStatus (state, payload) {
            state.signupResultStatus = payload
        },
        loadConfirmSignupStatus (state, payload) {
            state.confirmSignupStatus = payload
        }
    },
    actions: {
        setUserToken ({commit}, payload) {
            commit('setUserToken', payload)
        },
        clearSendPinCodeResultStatus({commit}) {
            commit('loadSendPinCodeResultStatus', {})
        },
        clearSignupResultStatus({commit}) {
            commit('loadSignupResultStatus', {})
        },
        clearConfirmSignupStatus({commit}) {
            commit('loadConfirmSignupStatus', {})
        },
        loadUserInfo ({commit}, authToken) {
            commit('userInfoLoadingSwitch', true)
            let url = `${backendPath}/api/v1/user/get-user-info/`
            Axios({
                method: 'get',
                headers: {
                    "Authorization": `Token ${authToken}`,
                },
                url: url,
            }).then((response) => {
                if (response.data.data[0]) {
                    commit('loadUserInfo', response.data.data[0])
                } else {
                    commit('setUserToken', "")
                }
            }).catch(function(error){
                if (error.response.status === 401) {
                    commit('setUserToken', "")
                }
                console.log(error)
            }).finally(() => {
                commit('userInfoLoadingSwitch', false)
            })
        },
        clearUserInfo({commit}, payload) {
            commit('loadUserInfo', payload)
        },
        requestForPinCode ({commit}, params) {
            let inputEmail = params.inputEmail
            commit('loadSendPinCodeResultStatus', {})
            let url = `${backendPath}/api/v1/user/get-pin/?recipient=${inputEmail}`
            Axios.get(url)
                .then(() => {
                    commit('loadSendPinCodeResultStatus', {
                        status: 'success',
                        message: 'PIN успешно отправлен'
                    })
                }).catch((error) => {
                    let errorData = {status: 'error'}
                    if (error.response.status == 404) {
                        errorData.message = 'Пользователь с таким email не зарегистрирован в базе данных'
                    }
                    commit('loadSendPinCodeResultStatus', errorData)
                    console.log(error)
                })
        },
        loginRequest ({commit}, params) {
            let data = {
                "username": params.inputEmail,
                "pincode": params.inputPinCode
            }
            commit('loadConfirmSignupStatus', "")
            let url = `${backendPath}/api/v1/user/get-token/`
            Axios({
                method: 'post',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: url,
                data: data,
            }).then(response => {
                if (response.data.data!=null) {
                    commit('setUserToken', response.data.data.token)
                    commit('loadLoginResultMessage', "Авторизация прошла успешно")
                } else {
                    commit('loadLoginResultMessage', "Введен неверный PIN")
                }
            }).catch(error => {
                console.log(error)
            })
        },
        signupRequest ({commit}, params) {
            let data = {
                "username": params.inputPhone,
                "email": params.inputEmail
            }
            let url = `${backendPath}/api/v1/user/`
            commit('loadSignupResultStatus', {})
            Axios({
                method: 'post',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: url,
                data: data,
            }).then(() => {
                commit('loadSignupResultStatus', { 
                        status: 'success',
                        message: 'Успешный запрос на регистрацию'
                })
            }).catch(error => {
                let errorData = {status: 'error'}
                if (error.response.status == 400) {
                    errorData.message = 'Пользователь с таким email уже зарегистрирован в базе данных!'
                } else {
                    errorData.message = 'При обработке данных, произошла ошибка. Попробуйте зарегистрироваться позже.'
                }
                commit('loadSignupResultStatus', errorData)
                console.log(error)
            })
        },
        confirmUserSignup ({commit}, user_id) {
            let url = `${backendPath}/api/v1/user/confirmation/?user_id=${user_id}`
            commit('loadConfirmSignupStatus', {})
            Axios.get(url)
                .then((response) => {
                    setTimeout(() => {
                        let status = 'success'
                        let message = ''
                        if (response.data.data == 'already activated') {
                            message = 'Пользователь уже был активирован ранее!'
                        } else if (response.data.data == 'activated') {
                            message = 'Пользователь успешно активирован!'
                        } else if (response.data.data == 'user with this user_id doest exist') {
                            status = 'error'
                            message = 'Пользователя с такими параметрами не существует в БД'
                        } else if (response.data.data == 'param user_id is empry or doest exist') {
                            status = 'error'
                            message = 'Не хватает данных для активации пользователя'
                        } 
                        commit('loadConfirmSignupStatus', {
                            status: status,
                            message: message
                        })
                    }, 3000)    
                }).catch((error) => {
                    commit('loadSendPinCodeResultStatus', {
                        status: 'error',
                        message: 'При активации пользователя произошла ошибка!'
                    })
                    console.log(error)
                })
        }
    }
}
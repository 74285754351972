<template>
    <div></div>
</template>

<script>
    import { defineComponent } from 'vue'
    import { useMessage } from 'naive-ui'
    export default defineComponent({
        name: "MessageComponent",
        setup () {
            const message = useMessage()
            return {
                success (text) {
                    message.success(
                        `${text}`,
                        {
                            keepAliveOnHover: true
                        }
                    )
                },
                info(text) {
                    message.info(
                        `${text}`,
                        {
                            keepAliveOnHover: true
                        }
                    )
                },
                error(text) {
                    message.error(
                        `${text}`,
                        {
                            keepAliveOnHover: true
                        }
                    )
                },
            }
        },
        computed: {
            sendPinCodeResultStatus () {
                return this.$store.getters.sendPinCodeResultStatus
            },
            loginResultMessage () {
                return this.$store.getters.loginResultMessage
            },
            addCartItemMessage () {
                return this.$store.getters.addCartItemMessage
            },
            delCartItemMessage () {
                return this.$store.getters.delCartItemMessage
            },
            addWishlistItemMessage () {
                return this.$store.getters.addWishlistItemMessage
            },
            delWishlistItemMessage () {
                return this.$store.getters.delWishlistItemMessage
            },
            signupResultStatus () {
                return this.$store.getters.signupResultStatus
            },
            confirmSignupStatus () {
                return this.$store.getters.confirmSignupStatus
            }
        },
        watch: {
            sendPinCodeResultStatus: {
                deep: true,
                handler() {
                    if (this.sendPinCodeResultStatus.status) {
                        if (this.sendPinCodeResultStatus.status == "success") {
                            this.success(this.sendPinCodeResultStatus.message)
                        } else {
                            this.error(this.sendPinCodeResultStatus.message)
                        }
                    }
                }
            },
            loginResultMessage: {
                deep: true,
                handler() {
                    if (this.loginResultMessage != "") {
                        if (this.loginResultMessage == 'Введен неверный PIN') {
                            this.error(this.loginResultMessage)
                        } else {
                            this.success(this.loginResultMessage)
                        }
                    }
                }
            },
            addCartItemMessage: {
                deep: true,
                handler() {
                    if (this.addCartItemMessage != "") {
                        this.success(this.addCartItemMessage)
                    }
                }
            },
            delCartItemMessage: {
                deep: true,
                handler() {
                    if (this.delCartItemMessage != "") {
                        this.success(this.delCartItemMessage)
                    }
                }
            },
            addWishlistItemMessage: {
                deep: true,
                handler() {
                    if (this.addWishlistItemMessage != "") {
                        this.success(this.addWishlistItemMessage)
                    }
                }
            },
            delWishlistItemMessage: {
                deep: true,
                handler() {
                    if (this.delWishlistItemMessage != "") {
                        this.success(this.delWishlistItemMessage)
                    }
                }
            },
            signupResultStatus: {
                deep: true,
                handler() {
                    if (this.signupResultStatus.status) {
                        if (this.signupResultStatus.status == "success") {
                            this.success(this.signupResultStatus.message)
                        } else {
                            this.error(this.signupResultStatus.message)
                        }
                    }
                }
            },
            confirmSignupStatus: {
                deep: true,
                handler() {
                    if (this.confirmSignupStatus.status) {
                        if (this.confirmSignupStatus.status == "success") {
                            this.success(this.confirmSignupStatus.message)
                        } else {
                            this.error(this.confirmSignupStatus.message)
                        }
                    }
                }
            }
        },
    })
</script>
<template>
    <td class="product_thumb">
        <router-link :to="{ name: 'good', params: { good_id: cartItem.good.id}}">
            <img v-if="mainImage" :src="mainImage" alt="" width="80">
            <img v-else :src="PreviewImage" alt="" width="80">
        </router-link>
    </td>
    <td class="product_name">
        <router-link :to="{ name: 'good', params: { good_id: cartItem.good.id}}">{{ cartItem.good.name }}</router-link>
    </td>
    <td class="product-price">{{ price }} &#8381;</td>
    <td class="product_quantity">
        <n-input-number 
            v-model:value="qty" 
            size="large" 
            @update:value="setGoodValue"
            :validator="validator"
            :update-value-on-input="false"
            />
    </td>
    <td class="product-price">{{ amount }} &#8381;</td>
    <td class="product_remove"><a @click="deleteGoodValue" href="#"><i class="fa fa-trash-o"></i></a></td>
</template>

<script>
import PreviewImage from '@/assets/images/product/ProductPreview.jpg'
import {backendPath} from "@/main.js"
export default {
    name: "CartItemComponent",
    props: ["cartItem"],
    setup() {
        return {
            validator: (x) => x > 0,
        }
    },  
    data () {
        return {
            PreviewImage,
            qty: Number(this.cartItem.quantity)
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        mainImage () {
            let path = ''
            if (this.cartItem.good.images.length > 0) {
                path = backendPath + this.cartItem.good.images[0].image.url
            }
            return path
        },
        client () {
            return this.$store.getters.client
        },
        price () {
            if (this.userToken && this.client.is_reseller) {
                return this.cartItem.good.price2
            } 
            return this.cartItem.good.price1
        },
        amount () {
            return (this.qty * Number(this.price)).toFixed(2)
        }
    },
    methods: {
        setGoodValue () {
            this.$store.dispatch('addSetDelCartItem', 
            {
                good_id: this.cartItem.good.id,
                quantity: this.qty,
                userToken: this.userToken,
                action: 'set'
            })
        },
        deleteGoodValue (el) {
            el.preventDefault()
            this.$store.dispatch('addSetDelCartItem', 
            {
                good_id: this.cartItem.good.id,
                quantity: this.qty,
                userToken: this.userToken,
                action: 'del'
            })
        }
    }
}
</script>

<style>
.n-input:not(.n-input--disabled):hover .n-input__state-border {
    border: 1px solid #fdb813 !important;
}
.n-input:not(.n-input--disabled).n-input--focus .n-input__state-border {
    border: 1px solid #fdb813 !important;
    box-shadow:  0 0 0 2px rgba(31, 24, 160, 0.2) !important;
}
.n-button:hover {
    color: #fdb813 !important;
}
.table_desc .cart_page table tbody tr td.product_quantity input {
    border: none;
}
</style>
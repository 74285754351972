<template>
    <div class="shop_banner">
        <img :src="catalogBanner" alt="">
    </div>
</template>

<script>
import catalogBanner from '@/assets/images/img/banner11.jpg'
export default {
    name: "CatalogBanner",
    data () {
        return {
            catalogBanner
        }
    }
}
</script>
<template>
    <BreadcrumbComponent name="Избранное" />
    <WishlistContentComponentVue />
</template>

<script>
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import WishlistContentComponentVue from '@/components/User/Wishlist/WishlistContentComponent.vue'
export default {
    name: "WishlistView",
    components: {
        BreadcrumbComponent,
        WishlistContentComponentVue,
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Избранное'
            }
        }
    }
}
</script>
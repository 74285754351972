<template>
    <MobileMenuComponent />
    <header class="header_area">
        <HeaderTopComponent />
        <HeaderMiddleComponent />
        <HeaderBottomComponent />
    </header>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import MobileMenuComponent from '@/components/Header/MobileMenuComponent.vue'
import HeaderTopComponent from '@/components/Header/HeaderTopComponent.vue'
import HeaderMiddleComponent from '@/components/Header/HeaderMiddleComponent.vue'
import HeaderBottomComponent from '@/components/Header/HeaderBottomComponent.vue'
export default {
    name: "HeaderComponent",
    setup() {
      const { cookies } = useCookies()
      return { cookies }
    },
    components: {
        MobileMenuComponent,
        HeaderTopComponent,
        HeaderMiddleComponent,
        HeaderBottomComponent
    },
    computed: {
      userToken () {
        return this.$store.getters.user_token
      }
    },
    mounted() {
      this.checkTheCookiesAuthToken()
      this.loadCart()
      this.loadWishlist()
    },
    methods: {
        checkTheCookiesAuthToken () {
          let authToken = this.cookies.get(`${cookiesToken}`)
          if (authToken) {
            this.$store.dispatch('setUserToken', authToken)
            this.$store.dispatch('loadUserInfo', authToken)
          }
        },
        loadCart () {
            let authToken = this.cookies.get(`${cookiesToken}`) 
            if (authToken) {
                this.$store.dispatch('loadCart', authToken)
            } else if (this.userToken) {
                this.$store.dispatch('loadCart', this.userToken)
            }
        },
        loadWishlist () {
            let authToken = this.cookies.get(`${cookiesToken}`) 
            if (authToken) {
                this.$store.dispatch('loadWishlist', authToken)
            } else if (this.userToken) {
                this.$store.dispatch('loadWishlist', this.userToken)
            }
        },
    },
    watch: {
      userToken: {
        deep: true,
        handler() {
          if (this.userToken) {
            this.cookies.set(`${cookiesToken}`, this.userToken)
            this.$store.dispatch('loadUserInfo', this.userToken)
          } else {
            this.cookies.remove(`${cookiesToken}`)
            this.$store.dispatch('clearUserInfo', {})
          }
        }
      },
      '$route': {
        immediate: true,
        handler() {
            if (this.cookies.get(`${cookiesToken}`)) {
                this.loadCart()
            }
            this.$store.dispatch('clearConfirmSignupStatus')
            this.$store.dispatch('clearSendPinCodeResultStatus')
            this.$store.dispatch('clearSignupResultStatus')
            if (this.$route.name != 'checkout') {
              this.$store.dispatch('clearCheckoutList')
            }
        },
      }
    }
}
</script>
<template>
    <div v-if="confirmSignupStatus.status" class="customer_login mt-32">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="about_content">
                        <h1>{{ confirmSignupStatus.message }}</h1>
                        <div class="checkout_btn">
                            <p>
                                <router-link :to="{ name: 'login'}" href="#">Войти в личный кабинет</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>            
    <n-modal 
        v-model:show="showModal"
        :mask-closable="false"
        :close-on-esc="false"
    >
        <n-card
        style="width: 600px"
        title=""
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >
        <n-space>
            <n-spin size="large" />
        </n-space>
        </n-card>
    </n-modal>
</template>

<script>
export default {
    name: "SignupConfirmView",
    components: {
    },
    data () {
        return {
            showModal: true,
        }
    },
    computed: {
        confirmSignupStatus () {
            return this.$store.getters.confirmSignupStatus
        }
    },
    mounted () {
        this.confirmUserSignup()
    },
    methods: {
        confirmUserSignup () {
            if (this.$route.query.user_id) {
                this.$store.dispatch('confirmUserSignup', 
                this.$route.query.user_id).then(() => {
                    setTimeout(() => {
                        this.showModal = false
                    }, 3000)
                })
            }
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Подтверждение регистрации'
            }
        }
    }
}
</script>

<style>
.n-space {
    justify-content: space-around !important;
}
</style>
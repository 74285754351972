<template>
    <GoodBreadCrumbs />
    <FullContentGoodComponent />
</template>

<script>
import GoodBreadCrumbs from '@/components/Good/GoodBreadCrumbs.vue'
import FullContentGoodComponent from '@/components/Good/FullContentGoodComponent.vue'
export default {
    name: "CatalogView",
    components: {
        GoodBreadCrumbs,
        FullContentGoodComponent    
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
            }
        }
    }
}
</script>
<template>
    <PageNotFoundComponent />
</template>

<script>
import PageNotFoundComponent from "@/components/Widgets/PageNotFoundComponent.vue"
export default {
    name: "PageNotFound",
    components: {
        PageNotFoundComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Магазин полива №1'
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
            }
        }
    }
}
</script>
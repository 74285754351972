import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        cart: [],
        cart_loading: false,
        addCartItemMessage: "",
        delCartItemMessage: "",
    },
    getters: {
        cart: state => {
            return state.cart
        },
        cartQty: state => {
            return state.cart.length
        },
        retailСartAmount: state => {
            if (state.cart.length > 0) {
                return state.cart.reduce((acc,item) => acc += (item.quantity*item.good.price1), 0)
            } else {
                return 0
            }
        },
        wholesaleСartAmount: state => {
            if (state.cart.length > 0) {
                return state.cart.reduce((acc,item) => acc += (item.quantity*item.good.price2), 0)
            } else {
                return 0
            }
        },
        cartItemById: state => id => {
            return state.cart.find(cartItem => cartItem.good.id === id)
        },
        cart_loading: state => {
            return state.cart_loading
        },
        sendCartResultMessage: state => {
            return state.sendCartResultMessage
        },
        addCartItemMessage: state => {
            return state.addCartItemMessage
        },
        delCartItemMessage: state => {
            return state.delCartItemMessage
        }
    },
    mutations: {
        loadCartData (state, payload) {
            state.cart = payload
        },
        cartLoadingSwitch (state, payload) {
            state.cart_loading = payload
        },
        loadSendCartResultMessage (state, payload) {
            state.sendCartResultMessage = payload
        },
        loadAddCartItemMessage (state, payload) {
            state.addCartItemMessage = payload
        },
        loadDelCartItemMessage (state, payload) {
            state.delCartItemMessage = payload
        }
    },
    actions: {
        clearCart({commit}) {
            commit('loadCartData', [])
        },
        async addSetDelCartItem({commit}, params) {
            let good_id = params.good_id
            let quantity = params.quantity
            let action = params.action
            let userToken = params.userToken
            commit('cartLoadingSwitch', true)
            commit('loadAddCartItemMessage', "")
            commit('loadDelCartItemMessage', "")
            let data = {
                "data" : [
                    {
                        "good_id" : good_id,
                        "quantity": Number(quantity),
                    }
                ]
            }
            let url = ''
            if (action == 'add') {
                url = `${backendPath}/api/v1/cart/add/`
            } else if (action == 'set') {
                url = `${backendPath}/api/v1/cart/set/`
            } else if (action == 'del') {
                url = `${backendPath}/api/v1/cart/delete/`
            }
            await Axios({
                method: 'post',
                headers: {
                    "Authorization": `Token ${userToken}`,
                },
                url: url,
                data: data
            }).then(response => {
                let cartList = response.data.data
                commit('loadCartData', cartList)
                if (action == 'add') {
                    commit('loadAddCartItemMessage', "Товар добавлен в корзину!")
                }
                if (action == 'del') {
                    commit('loadDelCartItemMessage', "Товар успешно удален из корзины!")
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('cartLoadingSwitch', false)
            })
        },
        async loadCart({commit}, userToken) {
            commit('cartLoadingSwitch', true)
            let url = `${backendPath}/api/v1/cart/`
            await Axios({
                method: 'get',
                headers: {
                    "Authorization": `Token ${userToken}`,
                },
                url: url,
            }).then(response => {
                let cartList = response.data.data
                commit('loadCartData', cartList)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('cartLoadingSwitch', false)
            })
        },
    }
}
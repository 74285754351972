<template>
    <BreadcrumbComponent name="Контакты" />
</template>

<script>
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
export default {
    name: "ContactView",
    components: {
        BreadcrumbComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Контакты'
            }
        }
    }
}
</script>
<template>
    <BreadcrumbComponent 
        name="Каталог"
        :categoryName="category.name"
    >
    </BreadcrumbComponent>
    <div class="shop_area shop_reverse">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12">
                    <SidebarComponent />
                </div>
                <div class="col-lg-9 col-md-12">
                    <CatalogBanner />
                    <div class="shop_title">
                        <h1 v-if="category">{{ category.name }}</h1>
                        <h1 v-else>Каталог</h1>
                    </div>
                    <!-- <CatalogToolbar /> -->
                    <div v-if="goods_list_loading" class="row shop_wrapper grid_3">
                        <CatalogPreviewComponent />
                    </div>
                    <div v-else class="row shop_wrapper grid_3">
                        <div v-for="goodItem in goods_list" v-bind:key="goodItem.id" class="col-md-4 col-sm-6 col-lg-4">
                            <CatalogGoodComponent 
                                :goodInfo="goodItem"
                                :manufacturer="goodItem.manufacturer"
                                :images="goodItem.images"
                                >
                            </CatalogGoodComponent>
                        </div>
                    </div>
                    <PaginationComponent /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationComponent from '@/components/Catalog/PaginationComponent.vue'
import SidebarComponent from '@/components/Catalog/SidebarComponent.vue'
import CatalogBanner from '@/components/Catalog/CatalogBanner.vue'
import BreadcrumbComponent from '@/components/Basic/BreadcrumbComponent.vue'
import CatalogGoodComponent from '@/components/Catalog/CatalogGoodComponent.vue'
import CatalogPreviewComponent from '@/components/Catalog/CatalogPreviewComponent.vue'
// import CatalogToolbar from '@/components/Catalog/CatalogToolbar.vue'

export default {
    name: "CatalogContentComponent",
    components: {
        SidebarComponent,
        CatalogBanner,
        PaginationComponent,
        BreadcrumbComponent,
        CatalogGoodComponent,
        CatalogPreviewComponent
    },
    computed: {
        goodsQty() {
            return this.$store.getters.goods_qty
        },
        goods_list() {
            return this.$store.getters.goods_list
        },
        goods_list_loading() {
            return this.$store.getters.goods_list_loading
        },
        category () {
            return this.$store.getters.category
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                if (this.$route.query.category_id) {
                    this.$store.dispatch('loadCategoryInfo', this.$route.query.category_id)
                } else {
                    this.$store.dispatch('loadCategoryInfo', "")
                }
            }
        }
    }
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from '@/views/IndexPage.vue'
import CatalogView from '@/views/CatalogView.vue'
import GoodView from '@/views/GoodView.vue'
import AboutView from '@/views/AboutView.vue'
import ContactView from '@/views/ContactView.vue'
import LoginView from '@/views/User/LoginView.vue'
import SignupView from '@/views/User/SignupView.vue'
import SignupConfirmView from '@/views/User/SignupConfirmView.vue'
import AccountView from '@/views/User/Account/AccountView.vue'
import CartView from '@/views/User/CartView.vue'
import WishlistView from '@/views/User/WishlistView.vue'
import CheckoutView from '@/views/User/CheckoutView.vue'
import PageNotFound from '@/views/PageNotFound.vue'

const routes = [
    { 
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound 
      },
    {
        path: '/',
        name: 'home',
        component: IndexPage
        
    },
    {
        path: '/user/',
        children: [
            {
                path: 'account/',
                name: 'userAccount',
                component: AccountView
            },
            {
                path: 'cart/',
                name: 'userCart',
                component: CartView
            },
            {
                path: 'wishlist/',
                name: 'userWishlist',
                component: WishlistView
            },
            {
                path: 'checkout/',
                name: 'checkout',
                component: CheckoutView
            }
        ]
    },
    {
        path: '/catalog/',
        children: [
            {
                path: '',
                name: 'catalog',
                component: CatalogView
            },
            {
                path: 'good/:good_id/',
                name: 'good',
                component: GoodView
            },
        ]
    },
    {
        path: '/about-us/',
        name: 'about',
        component: AboutView
    },
    {
        path: '/contact/',
        name: 'contact',
        component: ContactView
    },
    {
        path: '/login/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/signup/',
        name: 'signup',
        component: SignupView
    },
    {
        path: '/confirmation/',
        name: 'confirmation',
        component: SignupConfirmView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
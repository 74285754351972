<template>
    <div class="error_section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="error_form">
                        <h1>404</h1>
                        <h2>Страница не найдена</h2>
                        <p>
                            Страница, которую вы искали, не создана или недоступна.
                        </p>
                        <router-link :to="{ name: 'home' }">На главную</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNotFoundComponent"
}
</script>
<template>
    <div v-if="goodsQty > 1" class="shop_toolbar t_bottom">
        <div class="pagination">
            <ul v-if="goods_list_loading">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <ul v-else>
                <li><a @click="previosPage"><i class="fa fa-chevron-left"></i></a></li>
                <li v-if="currentPage > 3">
                    <router-link v-if="q" :to="{ name: 'catalog', query: {q: q, page:1} }">1</router-link>
                    <router-link v-else-if="routeName=='catalog'"  :to="{ name: 'catalog', query: actualQuery(1) }">1</router-link>
                </li>
                <li v-if="currentPage > 3">
                    <a>...</a>
                </li>
                <li v-for="item in availablePagesBeforeCurrent" v-bind:key="item.id">
                    <router-link v-if="q" :to="{ name: 'catalog', query: {q: q, page:item} }">{{ item }}</router-link>
                    <router-link v-else-if="routeName=='catalog'" :to="{ name: 'catalog', query:  actualQuery(item) }">{{ item }}</router-link>
                </li>
                <li class="current">{{ currentPage }}</li>
                <li v-for="item in availablePagesAfterCurrent" v-bind:key="item.id">
                    <router-link v-if="q" :to="{ name: 'catalog', query: {q: q, page:item} }">{{ item }}</router-link>
                    <router-link v-else-if="routeName=='catalog'" :to="{ name: 'catalog', query:  actualQuery(item) }">{{ item }}</router-link>
                </li>
                <li v-if="(pageQty-currentPage) > 2">
                    <a>...</a>
                </li>
                <li v-if="(pageQty-currentPage) > 2">
                    <router-link v-if="q" :to="{ name: 'catalog', query: {q: q, page:pageQty} }">{{ pageQty }}</router-link>
                    <router-link v-else-if="routeName=='catalog'" :to="{ name: 'catalog', query: actualQuery(pageQty) }">{{ pageQty }}</router-link>
                </li>
                <li><a @click="nextPage"><i class="fa fa-chevron-right"></i></a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaginationComponent",
    computed: {
        q () {
            return this.$route.query.q
        },
        routeName () {
            return this.$route.name
        },
        goodsQty: function() {
            return this.$store.getters.goods_qty
        },
        pageQty () {
            return this.$store.getters.page_qty
        },
        currentPage () {
            return this.$store.getters.current_page
        },
        goods_list_loading () {
            return this.$store.getters.goods_list_loading
        },
        availablePagesBeforeCurrent () {
            return this.$store.getters.availablePagesBeforeCurrent
        },
        availablePagesAfterCurrent () {
            return this.$store.getters.availablePagesAfterCurrent
        }
    },
    methods: {
        loadCurrentGoodsList(data) {
            this.$store.dispatch('getGoodsList', data) 
        },
        setCurrentPage (page_num) {
            if (!this.$route.query.page == '') {
                this.$store.dispatch('setCurrentPage', page_num)
            } else {
                this.$store.dispatch('setCurrentPage', 1)
            }
        },
        nextPage: function(el) {
            el.preventDefault()
            if (this.goods_list_loading == false) {
                if (!(this.currentPage == this.pageQty)) {
                    this.setCurrentPage(this.currentPage + 1)
                    if (!this.q=='') {
                        this.$router.push({ name: 'catalog', query: {q:this.q, page:this.currentPage } })
                    } else {
                        let actualQuery = this.actualQuery(this.currentPage)
                        this.$router.push({ name: 'catalog', query: actualQuery})
                    }
                }    
            }
        },
        previosPage: function(el) {
            el.preventDefault()
            if (this.goods_list_loading == false) {
                if (this.currentPage > 1) {
                    this.setCurrentPage(this.currentPage - 1)
                    if (!this.q=='') {
                        this.$router.push({ name: 'catalog', query: {q:this.q, page:this.currentPage } })
                    } else {
                        let actualQuery = this.actualQuery(this.currentPage)
                        this.$router.push({ name: 'catalog', query: actualQuery})
                    }
                }
            }    
        },
        actualQuery (page) {
            let query = {
                page: page
            }
            return query
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                let query = this.$route.query
                if (!query.page == '') {
                    this.setCurrentPage(parseInt(query.page))
                } else {
                    this.setCurrentPage(1)
                }
                let data = {
                    "page" : this.currentPage
                }
                if (this.q!='') {
                    data.q = this.q
                }
                if (query.category!='') {
                    data.category_id = query.category_id
                }
                this.loadCurrentGoodsList(data)
            }
        }
    }
}
</script>
<template>
    <div class="header_bottom sticky-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="header_bottom_container header_position">
                        <div class="main_menu">
                            <nav>
                                <ul>
                                    <li><router-link :to="{ name: 'home' }">Домой</router-link></li>
                                    <li><router-link :to="{ name: 'catalog', query: {page: 1} }">Каталог</router-link></li>
                                    <li><router-link :to="{ name: 'about' }">О нас</router-link></li>
                                    <li><router-link :to="{ name: 'contact' }">Контакты</router-link></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="mini_cart_wrapper text-right">
                            <router-link :to="{ name: 'userCart' }"><span class="icon-shopping-cart"></span></router-link>
                            <span class="cart_quantity">{{ cartQty }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderBottomComponent",
    computed: {
        cartQty () {
            return this.$store.getters.cartQty
        },
    },
}
</script>
<template>
    <div class="header_middle">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6">
                    <div class="logo">
                        <router-link :to="{name: 'home'}"><img :src="logo" alt="Магазин полива №1"></router-link>
                    </div>
                </div>
                <div class="col-lg-9 col-md-6">
                    <div class="middel_right">
                        <div class="search-container">
                            <div>
                                <div class="search_box">
                                    <input placeholder="Search entire store here ..." type="text">
                                    <button type="submit"><i class="icon-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="customer_support">
                            <div class="support_img_icon">
                                <img :src="iconPhone" alt="">
                            </div>
                            <div class="customer_support_text">
                                <p>
                                    <span>Поддержка</span>
                                    <a href="tel:0123456789">0123456789</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/images/logo/logo.png"
import iconPhone from "@/assets/images/icon/icon_phone.png"
export default {
    name: "HeaderMiddleComponent",
    data () {
        return {
            iconPhone,
            logo
        }
    }
}
</script>
<template>
    <PageNotFoundComponent v-if="goodInfoRequestError" />
    <div v-if="good.length != 0" class="product_details mt-30">
        <div v-if="goodinfoLoading" class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="product-details-tab">
                        <div class="slider_item">
                            <n-image
                                :src="ImagePreview" 
                                preview-disabled
                            />
                        </div> 
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="product_d_right">
                        <div>
                            <h1><n-skeleton height="40px" round /></h1>
                            <div class="price_box">
                                <n-skeleton height="40px" round />
                            </div>
                            <div class="product_meta">
                                <n-skeleton height="40px" round />
                            </div>
                            <div class="product_meta">
                                <n-skeleton height="40px" round />
                            </div>
                            <div class="product_variant quantity">
                                <n-skeleton height="40px" round />
                                <n-skeleton height="40px" round />   
                            </div>
                            <div class="product_d_action">
                                <ul>
                                    <li>
                                        <n-skeleton height="40px" circle />
                                    </li>
                                </ul>
                            </div>
                            <div class="product_meta">
                                <n-skeleton height="40px" round />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="product-details-tab">
                        <div class="slider_item">
                            <n-image
                                v-if="mainImage" 
                                :src="mainImage" 
                                :alt="good.name"
                            />
                            <n-image
                                v-else
                                :src="ImagePreview" 
                                preview-disabled
                            />
                        </div> 
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="product_d_right">
                        <div>
                            <h1>{{ good.name }}</h1>
                            <div class="price_box">
                                <span class="current_price">{{ price }} &#8381;</span>
                            </div>
                            <div class="product_meta">
                                <span v-if="goodinfoLoading">Артикул: - </span>
                                <span v-else>Артикул: {{ good.art }}</span>
                            </div>
                            <div class="product_meta">
                                <span v-if="goodinfoLoading">Остаток: - шт.</span>
                                <span v-else>Остаток: {{ balance }} шт.</span>
                            </div>
                            <div class="product_variant quantity">
                                <n-input-number 
                                    v-model:value="qty" 
                                    size="large" 
                                    :validator="validator"
                                    :update-value-on-input="false"
                                />
                                <n-space vertical>
                                    <n-spin :show="cartAddLoading">
                                        <a @click="addToCart" class="button" type="submit">В корзину</a>
                                    </n-spin>
                                </n-space>    
                            </div>
                            <div class="product_d_action">
                                <ul>
                                    <li>
                                        <n-button @click="addDelWishlistItem" tertiary circle type="warning" size="large">
                                            <template #icon>
                                                <n-space vertical>
                                                    <n-spin :show="wishlistAddDelLoading">
                                                        <n-icon>
                                                            <i v-if="itemInWishlist" class="icon-heart2"></i>
                                                            <i v-else class="icon-heart"></i>
                                                        </n-icon>
                                                    </n-spin>
                                                </n-space>  
                                            </template>
                                        </n-button>
                                    </li>
                                </ul>
                            </div>
                            <div class="product_meta">
                                <span>Категория: 
                                    <router-link 
                                        v-if="category" 
                                        :to="{ name: 'catalog', query: {category_id: category.id, page: 1}}"
                                    >{{ category.name }}
                                    </router-link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImagePreview from '@/assets/images/product/productbig2.jpg'
import { backendPath } from '@/main.js'
import PageNotFoundComponent from '@/components//Widgets/PageNotFoundComponent.vue'
export default {
    name: "FullContentGoodComponent",
    components: {
        PageNotFoundComponent
    },
    setup() {
        return {
            validator: (x) => x > 0,
        }
    },  
    data () {
        return {
            ImagePreview,
            qty: 1,
            cartAddLoading: false,
            wishlistAddDelLoading: false,
        }
    },
    computed: {
        good () {
            return this.$store.getters.good
        },
        goodInfoRequestError () {
            return this.$store.getters.goodInfoRequestError
        },
        category () {
            return this.good.category
        },
        balance () {
            return Math.floor(this.good.balance)
        },
        goodinfoLoading () {
            return this.$store.getters.good_info_loading
        },
        userToken () {
            return this.$store.getters.user_token
        },
        mainImage () {
            let path = ''
            if (this.good.images) {
                if (this.good.images.length > 0) {
                    path = backendPath + this.good.images[0].image.url
                }
            }
            return path
        },
        client () {
            return this.$store.getters.client
        },
        price () {
            if (this.userToken && this.client.is_reseller) {
                return this.good.price2
            } 
            return this.good.price1
        },
        itemInWishlist () {
            let itemInWishlist = false 
            if (this.$store.getters.wishlistItemById(this.good.id)) {
                itemInWishlist = true
            }
            return itemInWishlist
        }
    },
    methods: {
        loadGoodInfo(id) {
            this.$store.dispatch('loadGoodInfo', id)
        },
        addToCart () {
            if (this.userToken && this.qty > 0) {
                this.cartAddLoading = true
                this.$store.dispatch('addSetDelCartItem', 
                {
                    good_id: this.good.id,
                    quantity: this.qty,
                    userToken: this.userToken,
                    action: 'add'
                }).then(() => {
                    setTimeout(() => {
                        this.cartAddLoading = false
                    }, 1000)
                })
            }
        },
        addDelWishlistItem () {
            if (this.userToken) {
                this.wishlistAddDelLoading = true
                let action = 'add'
                if (this.itemInWishlist) {
                    action = 'del'
                }
                this.$store.dispatch('addDelWishlistItem',
                {
                    good_id: this.good.id,
                    userToken: this.userToken,
                    action: action
                }).then(() => {
                    setTimeout(() => {
                        this.wishlistAddDelLoading = false
                    }, 1000)
                })
            }
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.loadGoodInfo(this.$route.params.good_id)
                if (this.good.length == 0) {
                    document.title = 'Магазин полива №1'
                }
            },
        },
        good: {
            deep: true,
            handler() {
                document.title = this.good.name
            }
        },
    },
}
</script>

<style >
.product_variant.quantity a {
    text-align: center;
}
.product_variant.quantity a:hover {
    color: #fff
}
.product_variant.quantity input {
    border: none;
}
</style>
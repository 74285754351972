<template>
    <div class="header_top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-9">
                    <div class="welcome_text">
                        <p v-if="user">Добро пожаловать, {{ user.username }}!</p>
                        <p v-else>Добро пожаловать!</p>
                    </div>
                </div>
                <div class="col-lg-9 col-md-9">
                    <div class="top_right text-right">
                        <ul>
                            <li v-if="user">
                                <router-link :to="{ name: 'userWishlist'}">
                                    <i class="icon-heart"></i> Избранное ({{ wishlistQty }})
                                </router-link>
                            </li>
                            <li v-if="!user"><router-link :to="{ name: 'signup' }">Регистрация</router-link></li>
                            <li v-if="!user"><router-link :to="{ name: 'login' }">Войти</router-link></li>
                            <li v-if="user"><router-link :to="{ name: 'userAccount'}">Личный кабинет</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderTopComponent",
    computed: {
        user () {
            return this.$store.getters.user
        },
        wishlistQty () {
            return this.$store.getters.wishlistQty
        }
    }
}
</script>
<template>
    <div v-if="signupResultStatus.status == 'success'" class="about_section mt-32">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="about_content">
                        <h1>На указанный EMAIL отправлено письмо!</h1>
                        <h4>Перейдите по ссылке из вложения для подтверждения регистрации</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="customer_login mt-32">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="account_form">
                        <h2>Регистрация</h2>
                        <div>
                            <p>
                                <label>Email <span>*</span></label>
                                <input 
                                    v-model="inputEmail" 
                                    :class="emailActiveClass" 
                                    type="text"
                                >
                            </p>
                            <div>
                                <label>Телефон <span>*</span></label>
                                <div 
                                    :class="phoneActiveClass" 
                                    class="as-phone-input"
                                >
                                    <span>+7</span>
                                    <input 
                                        v-phone
                                        v-model="inputPhone" 
                                        type="text"
                                    >
                                </div>
                            </div>
                            <div class="login_submit">
                                <button @click="signupRequest">
                                    <n-space vertical>
                                        <n-spin :show="signupLoading">
                                            Зарегистрироваться
                                        </n-spin>
                                    </n-space>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SignupComponent",
    data () {
        return {
            inputEmail: "",
            inputPhone: "",
            signupLoading: false
        }
    },
    computed: {
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.inputEmail)
        },
        emailActiveClass () {
            let activeClass = ''
            if (this.isValidEmail) {
                activeClass = 'success'
            } else {
                activeClass = 'error'
            }
            if (this.inputEmail=="") {
                activeClass = ''
            }
            return activeClass
        },
        phoneActiveClass () {
            let activeClass = ''
            if (this.inputPhone.length === 12) {
                activeClass = 'success'
            } else {
                activeClass = 'error'
            }
            if (this.inputPhone=='') {
                activeClass = ''
            }
            return activeClass
        },
        signupResultStatus () {
            return this.$store.getters.signupResultStatus
        }
    },
    methods: {
        signupRequest () {
            if (this.isValidEmail && this.inputPhone.length === 12) {
                this.signupLoading = true
                this.$store.dispatch('signupRequest', 
                {
                    inputEmail: this.inputEmail,
                    inputPhone: `7${this.inputPhone.replaceAll('-', '')}`
                }).then(() => {
                    setTimeout(() => {
                        this.signupLoading = false
                    }, 1500)
                })
            }
        }
    },
    directives: {
        phone: {
            mounted(el) {
                function replaceNumberForInput(value) {
                    let val = ''
                    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                    val = x[1] + (x[2] ? '-' + x[2] : '') + (x[3] ? '-' + x[3] : '')
                    return val
                }
                el.oninput = function(e) {
                    if (!e.isTrusted) {
                        return
                    }
                    this.value = replaceNumberForInput(this.value)
                },
                el.onblur = function() {

                }
            }
        }
    },
}
</script>

<style>
div.vue-pincode-input-wrapper {
  justify-content: space-around;
}
input.error {
    border-color: #dc3545;
}
input.success {
    border-color: #198754;
}
.as-phone-input {
    border: 1px solid #ededed;
    margin-bottom: 20px;
}
.as-phone-input.error {
    border-color: #dc3545;
}
.as-phone-input.success {
    border-color: #198754;
}
.as-phone-input input {
    border: none;
    padding: 0 50px;
}
.as-phone-input span {
    position: absolute;
    padding: 8px 10px;
    border-right: 1px solid #ededed;
}
.about_content h1 {
    text-transform: none;
}
</style>input
<template>
    <div 
        v-for="item in demoContentCount"
        :key="item.id" 
        class="col-md-4 col-sm-6 col-lg-4"
    >
        <div class="single_product">
            <div class="product_thumb">
                <a class="primary_img" href="#"><img :src="PreviewImage" alt=""></a>
            </div>
            <div class="product_content grid_content">
            </div>
        </div>
    </div>    
</template>

<script>
import PreviewImage from '@/assets/images/product/ProductPreview.jpg'
export default {
    name: "CatalogPreviewComponent",
    data() {
        return {
            demoContentCount: 27,
            PreviewImage
        }
    }
}
</script>
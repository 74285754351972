<template>
    <div class="off_canvars_overlay" :class="{ active : showMobileMenu }">
    </div>
    <div class="offcanvas_menu">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="canvas_open" @click="showMobileMenu = !showMobileMenu">
                        <a><i class="icon-menu"></i></a>
                    </div>
                    <div class="offcanvas_menu_wrapper" :class="{ active : showMobileMenu }">
                        <div class="canvas_close" @click="showMobileMenu = !showMobileMenu">
                            <a><i class="icon-x"></i></a>
                        </div>
                        <div class="welcome_text">
                            <p>Добро пожаловать!</p>
                        </div>
                        <div class="top_right">
                            <ul>
                                <li v-if="user">
                                    <router-link :to="{ name: 'userWishlist' }">
                                        <i class="icon-heart"></i> Избранное ({{ wishlistQty }})
                                    </router-link>
                                </li>
                                <li v-if="!user"><router-link :to="{ name: 'login' }">Вход</router-link></li>
                                <li v-if="user">
                                    <router-link :to="{ name: 'userAccount' }">Личный кабинет</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="search-container">
                            <form action="#">
                                <div class="search_box">
                                    <input placeholder="Search entire store here ..." type="text">
                                    <button type="submit"><i class="icon-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <div class="customer_support">
                            <div class="support_img_icon">
                                <img :src="iconPhone" alt="">
                            </div>
                            <div class="customer_support_text">
                                <p>
                                    <span>Поддержка</span>
                                    <a href="tel:0123456789">0123456789</a>
                                </p>
                            </div>
                        </div>
                        <div class="mini_cart_canvas">
                            <div class="mini_cart_wrapper">
                                <router-link :to="{ name: 'userCart' }">
                                    <span class="icon-shopping-cart"></span>
                                </router-link>
                                <span class="cart_quantity">{{ cartQty }}</span>
                            </div>
                        </div>
                        <div id="menu" class="text-left ">
                            <ul class="offcanvas_main_menu">
                                <li class="menu-item-has-children">
                                    <router-link :to="{ name: 'home' }">Домой</router-link>
                                </li>
                                <li class="menu-item-has-children">
                                    <router-link :to="{ name: 'catalog', query: {page: 1} }">Каталог</router-link>
                                </li>
                                <li class="menu-item-has-children">
                                    <router-link :to="{ name: 'about' }">О нас</router-link>
                                </li>
                                <li class="menu-item-has-children">
                                    <router-link :to="{ name: 'contact' }">Контакты</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import iconPhone from "@/assets/images/icon/icon_phone.png"

export default {
    name: "MobileMenuComponent",
    data () {
        return {
            iconPhone,
            showMobileMenu: false,
        }
    },
    computed: {
        cartQty () {
            return this.$store.getters.cartQty
        },
        wishlistQty () {
            return this.$store.getters.wishlistQty
        },
        user () {
            return this.$store.getters.user
        }
    }
}
</script>
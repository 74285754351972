<template>
    <div class="single_product">
        <div class="product_thumb">
            <router-link
                :to="{ name: 'good', params: { good_id: id}}"
            >
                <n-image
                    v-if="mainImage"
                    lazy
                    :src="mainImage"
                    :alt="name"
                    preview-disabled
                />
                <n-image
                    v-else
                    :src="PreviewImage"
                    preview-disabled
                />
            </router-link>
            <div class="action_links">
                <ul>
                    <li class="wishlist">
                        <n-button @click="addDelWishlistItem" strong secondary circle type="warning" size="large">
                            <template #icon>
                                <n-space vertical>
                                    <n-spin :show="wishlistAddDelLoading">
                                        <n-icon>
                                            <i v-if="itemInWishlist" class="icon-heart2"></i>
                                            <i v-else class="icon-heart"></i>
                                        </n-icon>
                                    </n-spin>
                                </n-space>  
                            </template>
                        </n-button>
                    </li>
                </ul>
            </div>
            <div class="add_to_cart">
                <n-space vertical>
                    <n-spin :show="cartAddLoading">
                        <a @click="addToCart" class="button">В корзину</a>
                    </n-spin>
                </n-space>  
            </div>
        </div>
        <div class="product_content grid_content">
            <p v-if="manufacturerName" class="manufacture_product"><a href="#">{{ manufacturerName }}</a></p>
            <h4>
                <router-link :to="{ name: 'good', params: { good_id: id}}">{{ name }}</router-link>
            </h4>
            <div class="price_box">
                <span>Цена: </span>
                <span class="current_price">{{ price }} &#8381;</span>
            </div>
            <div class="balance-box">
                <span>Остаток: </span>
                <span class="current_balance">{{ balance }} шт.</span>
            </div>
        </div>
    </div>
</template>

<script>
import PreviewImage from '@/assets/images/product/ProductPreview.jpg'
import {backendPath} from "@/main.js"
export default {
    name: "CatalogGoodComponent",
    props: ["goodInfo", "manufacturer", "images"],
    data () {
        return {
            PreviewImage,
            id: this.goodInfo.id,
            name: this.goodInfo.name,
            balance: Math.floor(this.goodInfo.balance),
            manufacturerName: this.manufacturer ? this.manufacturer.name : "",
            wishlistAddDelLoading: false,
            cartAddLoading: false
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        client () {
            return this.$store.getters.client
        },
        price () {
            if (this.userToken && this.client.is_reseller) {
                return this.goodInfo.price2
            } 
            return this.goodInfo.price1
        },
        mainImage () {
            let path = ''
            if (this.goodInfo.images.length > 0) {
                path = backendPath + this.goodInfo.images[0].image.url
            }
            return path
        },
        itemInWishlist () {
            let itemInWishlist = false 
            if (this.$store.getters.wishlistItemById(this.id)) {
                itemInWishlist = true
            }
            return itemInWishlist
        }
    },
    methods: {
        addToCart () {
            if (this.userToken) {
                this.cartAddLoading = true
                this.$store.dispatch('addSetDelCartItem', 
                {
                    good_id: this.id,
                    quantity: 1,
                    userToken: this.userToken,
                    action: 'add'
                }).then(() => {
                    setTimeout(() => {
                        this.cartAddLoading = false
                    }, 1000)
                })
            }
        },
        addDelWishlistItem () {
            if (this.userToken) {
                this.wishlistAddDelLoading = true
                let action = 'add'
                if (this.itemInWishlist) {
                    action = 'del'
                }
                this.$store.dispatch('addDelWishlistItem',
                {
                    good_id: this.id,
                    userToken: this.userToken,
                    action: action
                }).then(() => {
                    setTimeout(() => {
                        this.wishlistAddDelLoading = false
                    }, 1000)
                })
            }
        }
    }

}
</script>
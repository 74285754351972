<template>
    <BreadcrumbComponent name="Оформление заказа" />
    <CheckoutContentComponent />
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import CheckoutContentComponent from "@/components/User/Checkout/CheckoutContentComponent.vue"
export default {
    name: "CheckoutView",
    components: {
        BreadcrumbComponent,
        CheckoutContentComponent
    },
    setup() {
		const { cookies } = useCookies()
		return { cookies }
	},
    mounted () {
        let authToken = this.cookies.get(`${cookiesToken}`)
        if (!authToken) {
            this.$router.push({ name: 'home'})
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Оформление заказа'
            }
        }
    }
}
</script>
<template>
    <div class="breadcrumbs_area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb_content">
                        <ul v-if="!categoryName">
                            <li><router-link :to="{ name: 'home' }">Домой</router-link></li>
                            <li>{{ name }}</li>
                        </ul>
                        <ul v-else>
                            <li><router-link :to="{ name: 'home' }">Домой</router-link></li>
                            <li><router-link :to="{ name: 'catalog', query: {page: 1} }">{{ name }}</router-link></li>
                            <li>{{ categoryName }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BreadcrumbComponent",
    props: ["name", "categoryName"]
}
</script>
<template>
    <div v-if="!cart_loading && cartQty < 1" class="about_section mt-32">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="about_content">
                        <h1>Ваша корзина пуста</h1>
                        <div class="checkout_btn">
                            <p>
                                <router-link :to="{ name: 'catalog', query: { page: 1}}" href="#">В каталог</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="shopping_cart_area mt-32">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="table_desc">
                        <div class="cart_page table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="product_thumb">Изображение</th>
                                        <th class="product_name">Товар</th>
                                        <th class="product-price">Цена</th>
                                        <th class="product_quantity">Количество</th>
                                        <th class="product_total">Сумма</th>
                                        <th class="product_remove"></th>
                                    </tr>
                                </thead>
                                <CartPreviewComponent v-if="cart_loading" :demoCartItemsCount="5" />
                                <tbody v-else>
                                    <tr v-for="cartItem in cart" :key="cartItem.id">
                                        <CartItemComponent :cartItem="cartItem" />
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="cart-total-label product-price">Итого:</td>
                                        <td class="product-price">{{ cartAmount }} &#8381;</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="coupon_area">
                <div class="row justify-content-end">
                    <div class="col-lg-6 col-md-6">
                        <div class="right">
                            <div class="coupon_inner">
                                <div class="checkout_btn">
                                    <router-link :to="{ name: 'checkout' }">Перейти к оформлению</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CartPreviewComponent from '@/components/User/Cart/CartPreviewComponent.vue'
import CartItemComponent from '@/components/User/Cart/CartItemComponent.vue'
export default {
    name: "CartContentComponent",
    components: {
        CartPreviewComponent,
        CartItemComponent
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        cart () {
            return this.$store.getters.cart
        },
        client () {
            return this.$store.getters.client
        },
        cartQty () {
            return this.$store.getters.cartQty
        },
        cartAmount () {
            if (this.userToken && this.client.is_reseller) {
                return this.$store.getters.wholesaleСartAmount.toFixed(2)
            } 
            return this.$store.getters.retailСartAmount.toFixed(2)
        },
        cart_loading () {
            return this.$store.getters.cart_loading
        },
    }
}
</script>

<style>
.checkout_btn p {
    margin-top: 30px;
    text-align: center;
}
.cart-total-label {
    text-align: right !important;
}
</style>
<template>
    <div v-if="!checkoutListLoading && checkoutList.length < 1" class="about_section mt-32">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="about_content">
                        <h1>Корзина пуста, невозможно разместить заказ.</h1>
                        <div class="checkout_btn">
                            <p>
                                <router-link :to="{ name: 'catalog', query: { page: 1}}" href="#">В каталог</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="Checkout_section mt-32">
        <div class="container">
            <div class="checkout_form">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="checkout-form">
                            <h3>Информация о заказе</h3>
                            <div class="order_table table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Товар</th>
                                            <th>Цена Розница</th>
                                            <th>Цена Опт</th>
                                            <th>Цена Клиент</th>
                                            <th>Количество</th>
                                            <th>Сумма</th>
                                        </tr>
                                    </thead>
                                    <CheckoutPreviewComponent v-if="checkoutListLoading" :previewCheckoutItemsCount="5" />
                                    <tbody v-else>
                                        <tr v-for="checkoutItem in checkoutList" :key="checkoutItem.id">
                                            <CheckoutItemComponent :checkoutItem="checkoutItem" />
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="order_total">
                                            <td colspan="4"></td>
                                            <td>Итого:</td>
                                            <td><strong>{{ checkoutAmount }} &#8381;</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="order_button">
                                <n-space vertical>
                                    <n-spin :show="orderCreationLoading">
                                        <button @click="placeAnOrder">Разместить заказ</button>
                                    </n-spin>
                                </n-space> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <n-modal 
        :show="orderCreationShowModal"
        :close-on-esc="false"
        :mask-closable="false"
    >
        <n-card
        style="width: 600px"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >
        <div>
            <n-result :status="orderCreationStatus.status" :title="orderCreationStatus.message">
                <template #footer>
                </template>
            </n-result>
        </div>
        <template #footer>
            <div class="order_button modal-footer">
                <button @click="okButtonHandler">OK</button>
            </div>
        </template>
        </n-card>
    </n-modal>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import CheckoutPreviewComponent from '@/components/User/Checkout/CheckoutPreviewComponent.vue'
import CheckoutItemComponent from '@/components/User/Checkout/CheckoutItemComponent.vue'
export default {
    name: "CheckoutContentComponent",
    components: {
        CheckoutPreviewComponent,
        CheckoutItemComponent
    },
    setup() {
      const { cookies } = useCookies()
      return { cookies }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        checkoutList () {
            return this.$store.getters.checkoutList
        },
        checkoutAmount () {
            return this.$store.getters.checkoutAmount.toFixed(2)
        },
        checkoutListLoading () {
            return this.$store.getters.checkoutListLoading
        },
        orderCreationLoading () {
            return this.$store.getters.orderCreationLoading
        },
        orderCreationStatus () {
            return this.$store.getters.orderCreationStatus
        },
        orderCreationShowModal () {
            if (this.orderCreationStatus.status) {
                return true
            } else {
                return false
            }
        }
    },
    mounted () {
        this.loadCheckoutList()
    },
    methods: {
        okButtonHandler () {
            if (this.orderCreationStatus.status == 'success') {
                this.$router.push({ name: 'userAccount'})
                this.$store.dispatch('clearCart')
                this.$store.dispatch('clearCheckoutList')
            } else if (this.orderCreationStatus.status == 'error') {
                this.$router.push({ name: 'userCart'})
            }
            this.$store.dispatch('clearOrderCreationStatus')
        },
        loadCheckoutList () {
            let authToken = this.cookies.get(`${cookiesToken}`) 
            if (authToken) {
                this.$store.dispatch('loadCheckoutList', authToken)
            } else if (this.userToken) {
                this.$store.dispatch('loadCheckoutList', this.userToken)
            }
        },
        placeAnOrder () {
            if (this.userToken) {
                let itemsList = []
                this.checkoutList.forEach((item) => {
                    itemsList.push({
                        good_id: item.good.id,
                        quantity: Number(item.quantity).toFixed(2),
                        price: Number(item.good.clientPrice).toFixed(2),
                        summ: (item.quantity * item.good.clientPrice).toFixed(2)
                    })
                })
                this.$store.dispatch('placeAnOrder', {
                    authToken: this.userToken,
                    checkoutItems: itemsList
                })
            }
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.loadCheckoutList()
            }
        }
    }
}
</script>

<style>
.modal-footer {
    display: flex;
    justify-content: center !important;
    padding-right: 0 !important;
}
.modal-footer button {
    width: 150px;
}
.order_button {
    display: flex;
    justify-content: end;
    padding-right: 20px;
}
.n-input:not(.n-input--autosize) {
    max-width: 70%;
}
@media (max-width: 1199px) {
    .n-input:not(.n-input--autosize) {
        max-width: 100%;
    }
}
</style>
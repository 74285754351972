<template>
  <n-modal-provider>
    <n-message-provider>
    <HeaderComponent />
      <router-view/>
      <MessageComponent />
    <FooterComponent />
    </n-message-provider>
  </n-modal-provider>
</template>

<script>
import HeaderComponent from "@/components/Header/HeaderComponent.vue"
import FooterComponent from "@/components/Footer/FooterComponent.vue"
import MessageComponent from '@/components/Widgets/MessageComponent.vue'
export default {
    components: {
      MessageComponent,
      HeaderComponent,
      FooterComponent,
    }
}
</script>
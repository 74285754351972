<template>
    <tbody>
        <tr v-for="item in demoCartItemsCount" :key="item.id">
            <td class="product_thumb"><n-skeleton height="40px" round /></td>
            <td class="product_name"><n-skeleton height="40px" round /></td>
            <td class="product-price"><n-skeleton height="40px" round /></td>
            <td class="product_quantity"><n-skeleton height="40px" round /></td>
            <td class="product_total"><n-skeleton height="40px" round /></td>
            <td class="product_remove"><n-skeleton height="40px" round /></td>
        </tr>
        <tr>
            <td colspan="4"><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
            <td><n-skeleton height="40px" round /></td>
        </tr>
    </tbody>
</template>

<script>
export default {
    name: "CartPreviewComponent",
    props: ['demoCartItemsCount']
}
</script>
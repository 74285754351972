<template>
    <BreadcrumbComponent name="Авторизация" />
    <LoginComponent />
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import LoginComponent from "@/components/User/LoginComponent.vue"
export default {
    name: "LoginView",
    setup() {
		const { cookies } = useCookies()
		return { cookies }
	},
    mounted () {
        let authToken = this.cookies.get(`${cookiesToken}`)
        if (authToken) {
            this.$router.push({ name: 'home'})
        }
    },
    components: {
        BreadcrumbComponent,
        LoginComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Авторизация'
            }
        }
    }
}
</script>
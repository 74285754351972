<template>
    <div v-if="orderListTotal>0" class="shop_toolbar t_bottom">
        <div class="pagination">
            <ul v-if="ordersListLoading">
                <li><n-skeleton :sharp="false" size="small" /></li>
                <li><n-skeleton :sharp="false" size="small" /></li>
                <li><n-skeleton :sharp="false" size="small" /></li>
                <li><n-skeleton :sharp="false" size="small" /></li>
                <li><n-skeleton :sharp="false" size="small" /></li>
                <li><n-skeleton :sharp="false" size="small" /></li>
            </ul>
            <ul v-else>
                <li v-if="currentPage==1" class="disabled-button">
                    <a 
                        href="#"
                        @click.prevent=""
                    >
                        <i class="fa fa-chevron-left"></i>
                    </a>
                </li>
                <li v-else>
                    <a
                        href="#" 
                        @click.prevent="setCurrentPage(currentPage - 1)"
                    >
                        <i class="fa fa-chevron-left"></i>
                    </a>
                </li>
                <li v-if="currentPage > 3">
                    <a href="#" @click.prevent="setCurrentPage(1)">1</a>
                </li>
                <li v-if="currentPage > 3">
                    <a>...</a>
                </li>
                <li v-for="item in orderAvailPagesBeforeCurrent" v-bind:key="item.id">
                    <a href="#"  @click.prevent="setCurrentPage(item)">{{ item }}</a>
                </li>
                <li class="current">{{ currentPage }}</li>
                <li v-for="item in orderAvailPagesAfterCurrent" v-bind:key="item.id">
                    <a href="#" @click.prevent="setCurrentPage(item)">{{ item }}</a>
                </li>
                <li v-if="(pageQty-currentPage) > 2">
                    <a>...</a>
                </li>
                <li v-if="(pageQty-currentPage) > 2">
                    <a href="#" @click.prevent="setCurrentPage(pageQty)">{{ pageQty }}</a>
                </li>
                <li v-if="currentPage==pageQty" class="disabled-button">
                    <a 
                        href="#"
                        @click.prevent=""
                    >
                        <i class="fa fa-chevron-right"></i>
                    </a>
                </li>
                <li v-else>
                    <a 
                        href="#"
                        @click.prevent="setCurrentPage(currentPage + 1)"
                    >
                        <i class="fa fa-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderListPagination",
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        ordersListLoading () {
            return this.$store.getters.ordersListLoading
        },
        ordersQty () {
            return this.$store.getters.orderListQty
        },
        pageQty () {
            return this.$store.getters.pageQty
        },
        currentPage () {
            return this.$store.getters.currentPage
        },
        orderAvailPagesBeforeCurrent () {
            return this.$store.getters.orderAvailPagesBeforeCurrent
        },
        orderAvailPagesAfterCurrent () {
            return this.$store.getters.orderAvailPagesAfterCurrent
        },
        orderListTotal () {
            return this.$store.getters.orderListTotal
        }
    },
    methods: {
        setCurrentPage (pageNumber) {
            this.$store.dispatch('setCurrentPage', pageNumber)
        },
        loadOrdersList () {
            this.$store.dispatch('loadOrdersList', { authToken: this.userToken, page: this.currentPage})
        },
    },
    watch: {
        currentPage: {
            deep: true,
            handler() {
                this.loadOrdersList()
            }
      }
    }
}
</script>

<style>
.disabled-button a {
    color: #666;
    cursor: default;
}
.disabled-button a:hover {
    background: #f1f1f1 !important;
    color: #666 !important
}
</style>
<template>
    <div v-if="!wishlist_loading && wishlistQty < 1" class="about_section mt-32">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="about_content">
                        <h1>В избранном пусто!</h1>
                        <div class="checkout_btn">
                            <p>
                                <router-link :to="{ name: 'catalog', query: { page: 1}}">В каталог</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="wishlist_area mt-32">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="table_desc wishlist">
                        <div class="cart_page table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="product_thumb">Изображение</th>
                                        <th class="product_name">Товар</th>
                                        <th class="product-price">Цена</th>
                                        <th class="product_quantity">Наличие</th>
                                        <th class="product_total"></th>
                                        <th class="product_remove"></th>
                                    </tr>
                                </thead>
                                <WishlistPreviewComponent v-if="wishlist_loading" :demoWishlistItemsCount="5" />
                                <tbody v-else>
                                    <tr v-for="wishlistItem in wishlist" :key="wishlistItem.id">
                                        <WishlistItemComponent
                                            :wishlistItem = wishlistItem
                                        />
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WishlistItemComponent from '@/components/User/Wishlist/WishlistItemComponent.vue'
import WishlistPreviewComponent from '@/components/User/Wishlist/WishlistPreviewComponent.vue'
export default {
    name: "WishlistContentComponent",
    components: {
        WishlistItemComponent,
        WishlistPreviewComponent
    },
    computed: {
        wishlist () {
            return this.$store.getters.wishlist
        },
        wishlistQty () {
            return this.$store.getters.wishlistQty
        },
        wishlist_loading () {
            return this.$store.getters.wishlist_loading
        },
    }
}
</script>
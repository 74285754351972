import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { globalCookiesConfig } from "vue3-cookies"
import { fullscreenImagePlugin } from 'vue-3-fullscreen-image-directive-plugin'
import 'vue-3-fullscreen-image-directive-plugin/style.css'
// import { useAccordion } from "vue3-rich-accordion"
// import "vue3-rich-accordion/accordion-library-styles.css"
import naive from "naive-ui"

import VueCollapsiblePanel from '@dafcoe/vue-collapsible-panel'
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'


export const backendPath = 'https://back.harin.annasoft.site'
export const cookiesToken = 'poliv-31-2f69e0bf-767f-4f94-98e2-798e9d99fadd'

globalCookiesConfig({
    expireTimes: "7d",
})

createApp(App).use(fullscreenImagePlugin).use(VueCollapsiblePanel).use(naive).use(store).use(router).mount('#app')
